import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container, Row, Col, Label, FormGroup, Input } from "reactstrap";
import { getCalcSetting, setCalcSetting } from '../../actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-dropdown-select';

const CalculationSetting = () => {
    const [ot, setOT] = useState(3);
    const [sheaveHead, setSheaveHead] = useState(15.375);
    const [rate, setRate] = useState(2);
    const [cylinders, setCylinders] = useState([]);
    const [railsSize, setRailsSize] = useState(10);

    const handleOT = (e) => {
        setOT(e.target.value);
    }

    const handleSheaveHead = (e) => {
        setSheaveHead(e.target.value);
    }

    const handleCylinders = (values) => {
        let data = values;
        data.sort((a, b) => parseInt(a.value) - parseInt(b.value));
        setCylinders(data);
    }

    const handleRate = (e) => {
        setRate(parseInt(e.target.value));
    }

    const handleRailsSize = (e) => {
        setRailsSize(parseInt(e.target.value));
    }

    const handleUpdate = () => {
        if(ot === "") {
            notify("Please input OT - Over Travel.")
            return;
        }
        if(sheaveHead === "") {
            notify("Please input Sheave Head.")
            return;
        }
        if(cylinders.length === 0) {
            notify("Please add at least one cylinder.")
            return;
        }

        const data = {
            ot: ot,
            sheave_head: sheaveHead,
            rate: rate,
            cylinders: JSON.stringify(cylinders),
            rails_size: railsSize
        };
        setCalcSetting(data).then((res) => {
            notify("Save Successfully!", true);
        }).catch(err => {
            console.log(err)
        })
    }

    const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

    useEffect(() => {
        getCalcSetting().then(res => {
            if(res.success) {
                setOT(res.data.ot);
                setSheaveHead(res.data.sheave_head);
                setRate(res.data.rate);
                setCylinders(JSON.parse(res.data.cylinders));
                setRailsSize(res.data.rails_size);
            }
        }).catch(err => {
            console.log(err)
        });
    }, [])

    return (
        <Fragment>
            <Breadcrumb title="Calculation" parent="Settings" />
            <Container fluid={true}>
                <Card>
                    <CardHeader>
                        <h5>Settings for Shaft Calculation</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Label className="col-form-label pt-0">
                                        <span style={{ color: "red" }}>*</span> OT - Over Travel
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        required=""
                                        placeholder="OT - Over Travel"
                                        name="ot"
                                        value={ot}
                                        onChange={handleOT}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label pt-0">
                                        <span style={{ color: "red" }}>*</span> Sheave Head
                                    </Label>
                                    <Input
                                        className="form-control"
                                        type="number"
                                        required=""
                                        placeholder="Sheave Head"
                                        name="sheave_head"
                                        value={sheaveHead}
                                        onChange={handleSheaveHead}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label">
                                        <span style={{ color: "red" }}>*</span> Rate
                                    </Label>
                                    <select 
                                        className="form-select" 
                                        required=""
                                        onChange={handleRate}
                                        value={rate}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label">
                                        <span style={{ color: "red" }}>*</span> Cylinders
                                    </Label>
                                    <Select
                                        multi
                                        create={true}
                                        values={cylinders}
                                        onChange={(values) => handleCylinders(values)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="col-form-label">
                                        <span style={{ color: "red" }}>*</span> Rails Size
                                    </Label>
                                    <select 
                                        className="form-select" 
                                        required=""
                                        onChange={handleRailsSize}
                                        value={railsSize}
                                    >
                                        <option value="8">8</option>
                                        <option value="10">10</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="btn-popup pull-right" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3rem' }}>
                            <div className="btn btn-secondary" onClick={handleUpdate}>
                                Save
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            <ToastContainer
				position="top-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<ToastContainer />
        </Fragment>
    );
};

export default CalculationSetting;
