import axios from 'axios';
import { SERVER_URL } from '../config';

export const addCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/addCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getCategories = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/getCategories`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/updateCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateCategoryVisible = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/updateCategoryVisible`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const deleteCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/deleteCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const addSubCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/addSubCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getSubCategories = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/getSubCategories`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateSubCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/updateSubCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const updateProduct = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/updateProduct`, data);
		return  res.data;
	} catch(err) {
		console.log(err);
		return ;
	}
}

export const deleteSubCategory = async (data) => {
	try {
		const response = await axios.post(`${SERVER_URL}/api/product/deleteSubCategory`, data);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export const getProductList = async () => {
	try {
		let res = await axios.get(`${SERVER_URL}/api/product/getProductList`) ;
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const addProduct = async (product) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/addProduct`, product);
		return res.data;
	} catch(err) {
		console.log(err);
		return ;
	}
}

export const deleteProduct = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/deleteProduct`, {id});
		return res.data;
	} catch(err) {
		console.log(err) ;
	}
}

export const getProductById = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/getProductById`, {id});
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getCategoryById = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/getCategoryById`, {id});
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const getSubCategoryById = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/product/getSubCategoryById`, {id});
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const addSyncProduct = async (product) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/sync_product/add`, product);
		return res.data;
	} catch(err) {
		console.log(err);
		return ;
	}
}

export const getSyncProductById = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/sync_product/getById`, {id});
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const deleteSyncProductById = async (id) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/sync_product/delete`, {id});
		return res.data;
	} catch(err) {
		console.log(err)
	}
}

export const updateSyncProduct = async (data) => {
	try {
		let res = await axios.post(`${SERVER_URL}/api/sync_product/update`, data);
		return  res.data;
	} catch(err) {
		console.log(err);
		return ;
	}
}